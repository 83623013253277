import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Footer from "../../../components/Footer";
import WhatsappChat from "../../../components/WhatsappChat";
import Bike from "../../../images/products/adBike.png";
import { localStorageKeys } from "../../../utility/localstorage";
import { getTranslatedText } from "../../../utility/constant";
import ProductPrefernceBanner from "../../../components/ProductPrefernceBanner";

const BikesPageIndex = ({}) => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <ProductPrefernceBanner
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        headlineOne="Apni Honda CD 70"
        headlineTwo="Chalayien"
        markHeadlineUp="Rs 11,650"
        headlineThreeUp="ki payment per"
        backgroundColorUp="#007290"
        descpOne="CreditPer ke paas aap ke liye behtreen plans maujood hain."
        descpTwo="3 asaan steps puray karien aur abhi apni Honda CD-70 chalayien!"
        displayDown="none"
        bikeImage={Bike}
      />

      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default BikesPageIndex;
